import axios from "axios";
import settings from '@/settings';

const apiPath = settings.apiPath()

import {moduleFactory, makeUpload, makeGet} from "@/store/modules/moduleFactory";

let module = moduleFactory('graphData', '/graph-data', {});

module.actions.getGraphDataCached = ({dispatch, getters}) => {
    return new Promise((resolve, reject) => {
        if(Object.keys(getters.graphData).length) {
            return resolve(getters.graphData);
        }
        return dispatch('getGraphData');
    });
}

module.actions.uploadNetworkNode = makeUpload('/upload/network-node');
module.actions.uploadStationDemand = makeUpload('/upload/station-demand');
module.actions.uploadEdge = makeUpload('/upload/edge');

const startDefault = 2010;
const endDefault = 2070;

// price_index/get-price-index/<int:source>/<int:start>/<int:end>/
module.actions.getMacroIndexValues = ({commit}, {id, start, end, prod_pi_ind}) => {

    const s = start || startDefault;
    const e = end || endDefault;
    return new Promise((resolve, reject) => {
        axios.get(apiPath + `/price-index/${id}?start=${s}&end=${e}&prod_pi_ind=${prod_pi_ind}`)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            });
    });
};

module.actions.getMacroIndex = makeGet('/price-index');

export default module;