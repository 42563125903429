const ruMonth = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

export default {
    /**
     * Parse date like php date()
     * @param {string} format - 'd.m.Y'
     * @param {string/object/undefined} date - '2015-03-25' || new Date() - default
     * @param {bool} isStrict - default = false
     * @returns {string}
     * @todo more formats marks
     */
    date(format, date, isStrict = false) {

        if (isStrict === true && !date)
            return '';

        var d = date !== undefined ? new Date(date) : new Date();

        var f = {
            d: d.getDate() < 10 ? "0" + d.getDate() : d.getDate(),
            m: d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1,
            Y: d.getFullYear(),
            H: d.getHours() < 10 ? "0" + d.getHours() : d.getHours(),
            i: d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes(),
            s: d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds()
        };

        var parse = function () {
            if (f[arguments[0]])
                return f[arguments[0]];
        };

        return format.replace(/(d+)|(m+)|(Y+)|(H+)|(i+)|(s+)/g, parse);
    },
    dateFromRuDate(date) {

        if(date instanceof Date){
            return date;
        }
        let tmp = String(date).split('.');
        let makeDate = date => {
            let d = new Date(date);
            if (isNaN(d)) {
                throw new Error(`Invalid date in "${date}"`);
            }
            return d;
        };

        if (tmp.length !== 3) {
            return makeDate(date);
        }
        return makeDate(tmp.reverse().join('-'));
    },
    /**
     * Number format
     * @param {int/string} n - value
     * @param {int} dl - length of decimal
     * @param {string} sd - sections delimiter
     * @param {string} dd - decimal delimiter
     * @returns {string}
     */
    numberFormat(n, dl, sd, dd) {
        if (isNaN(Number(n)) || n === null)
            return n;

        dl = isNaN(Number(dl)) ? 0 : Number(dl);
        sd = typeof sd === 'undefined' ? ' ' : sd;
        dd = typeof dd === 'undefined' ? '.' : dd;
        n = Number(n).toFixed(dl).replace(/./g, function (c, i, a) {
            return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? sd + c : c;
        });

        return dd !== '.' ? n.replace('.', dd) : n;
    },
    /**
     * Format number to local money
     * @param {number} num
     * @returns {string}
     */
    money(num) {
        return this.numberFormat(num, 2);
    },

    /**
     * unformat money string to number
     * @param {string} m
     * @returns {number}
     */
    fromMoney(m) {
        return typeof m !== 'string' ? m : m.split(' ').join('');
    },

    /**
     * Получить массив из размазанных данных
     * @param {object} data - объект с данными
     * @param {string} field - поле идентифицирующее размазанные данные (fieldname.0.field ...)
     * @returns Array
     */
    getSplitData(data, field) {

        let res = {};
        let key;

        for (var k in data) {
            if (k.indexOf(field + '.') === -1) {
                continue;
            }
            key = k.split('.');

            if (typeof res[key[1]] === 'undefined') {
                res[key[1]] = {};
            }

            res[key[1]][key[2]] = data[k];
        }
        return Object.values(res);
    },
    /**
     * Get uri params
     * @param {string} param - get param from GET
     * @returns {mixed} - object (without arg param), string (with param)
     */
    getQueryParams(param) {
        var g = decodeURI(location.search),
            result = {};
        if (g === '')
            return result;
        // разделяем переменные
        var tmp = (g.substr(1)).split('&');
        for (var i = 0; i < tmp.length; i++) {
            var t = tmp[i].split('=');
            // пары ключ(имя переменной)->значение
            var key = t[0];
            var value = t[1];
            // определяем массивы в запросе ( key[] )
            // @todo определять индекс массива
            if (key.match('\\[\\]')) {
                // это не первый элемент массива
                if (result[key] !== undefined) {
                    if (typeof result[key] !== 'object') {
                        var first = result[key];
                        result[key] = [first, value];
                    } else {
                        result[key].push(value);
                    }
                } else {
                    result[key] = [value];
                }
            } else {
                result[key] = value === undefined ? '' : value;
            }

        }
        return typeof param === 'string' && param ? result[param] : result;
    },
    /**
     * recursive check state
     * @param {object} state
     * @returns {Boolean}
     */
    hasErrors(state) {
        for (var k in state) {
            if (typeof state[k] === 'object') {
                if (this.hasErrors(state[k]) === true) {
                    return true;
                }
            }
            if (state[k] === false) {
                return true;
            }
        }
        return false;
    },
    labelRequired(label, isRequired) {

        let req = typeof isRequired === 'undefined' ? true : isRequired;

        if (!req) {
            return label + ':';
        }

        return label + ': <i class="text-danger">*</i>';
    },
    /**
     * Convert string regexp to RegExp
     * @param {string} inputstring
     * @returns {RegExp}
     */
    strToRegExp(inputstring) {
        let match = inputstring.match(new RegExp('^/(.*?)/([gimy]*)$'));
        // sanity check here
        let regex = new RegExp(match[1], match[2]);
        return regex;
    },
    /**
     * Format seconds to date (H:i:s)
     * @param {integer} int
     * @returns {String}
     */
    formatSeconds(int) {
        var date = new Date(null);
        date.setSeconds(int);
        return date.toISOString().substr(11, 8);
    },

    /**
     * decorate file size
     * @see http://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable
     * @param {int} bytes
     * @param {bool} si
     * @returns {String}
     */
    humanFileSize(bytes, si) {
        var thresh = si ? 1000 : 1024;
        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }
        var units = si
            ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        var u = -1;
        do {
            bytes /= thresh;
            ++u;
        } while (Math.abs(bytes) >= thresh && u < units.length - 1);
        return bytes.toFixed(1) + ' ' + units[u];
    },

    /**
     * Yes|No
     * @param {mixed} val
     * @returns {String}
     */
    yesNo(val) {
        return val ? 'Да' : 'Нет';
    },

    /**
     * Получить Месяц и Год из даты (Январь 2019)
     * @param {string|Date} date
     * @returns {String} - Январь 2019 | ''
     */
    ruMonthFromDate(date) {

        if (!date) {
            return '';
        }

        var m = ruMonth;
        var d = new Date(date);
        return m[d.getMonth()] + ' ' + d.getFullYear();
    },
    isRuMonth(str) {
        let reg = /^([А-Яа-я]+)[+|\s](\d{4})$/u;
        return reg.test(String(str));
    },
    dateFromMonth(month) {
        var result = String(month).match(/^([А-Яа-я]+)[+|\s](\d{4})$/u);

        if (!result) {
            return null;
        }

        var m = this.indexByMonth(result[1]);
        var y = result[2];
        return m && y ? new Date(y + '-' + (m < 10 ? '0' + m : m) + '-01') : null;
    },
    indexByMonth(month) {
        var m = ruMonth;

        var result = null;

        m.forEach((it, index) => {
            if (it.toLowerCase() === String(month).toLowerCase()) {
                result = index + 1;
            }
        });

        return result;
    },

    /**
     * General decorator
     * @param {mixed} value
     * @param {string} decorator
     * @returns {mixed}
     */
    decorate(value, decorator) {

        switch (decorator) {
            case 'day':
            case 'date':
                return this.date('d.m.Y', value);
            case 'datetime':
            case 'date_time':
                return this.date('d.m.Y H:i', value);
            case 'time':
                return this.date('H:i:s', value);
            case 'human_file_size':
            case 'humanFileSize':
                return this.humanFileSize(value);
            case 'yes_no':
                return this.yesNo(value);
            case 'number':
            case 'number_format':
                return this.numberFormat(value);
            case 'money':
            case 'decimal':
            case 'number_format_decimal':
                return this.numberFormat(value, 2);
        }

        if (typeof this[decorator] === 'function') {
            return this[decorator](value);
        }

        return value;
    }
};