<template>
  <div class="f-control-wrapper" :class="['f-v-' + variant]">
    <b-icon-chevron-down class="f-control-chevron" :class="chevronClass"/>
    <b-form-select
        class="f-control"
        :class="[{'no-select': isNoSelect}, 'input-' + size, control.class || '']"
        :value="value"
        @input="onChange('input',$event)"
        @change="onChange('change', $event)"
        :options="options"
        :text-field="textField"
        :value-field="valueField"
        v-bind="control"
    >
      <template #first v-if="first">
        <b-form-select-option :value="firstValue" class="placeholder">{{ placeholder }}</b-form-select-option>
      </template>
    </b-form-select>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number, Array],
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    textField: {
      type: String,
      default: 'name'
    },
    valueField: {
      type: String,
      default: 'id'
    },
    control: {
      type: Object,
      default: () => {
        return {};
      }
    },
    placeholder: {
      type: String,
      default: 'Выбрать',
    },
    first: {
      type: Boolean,
      default: false
    },
    firstValue: {
      default: null
    },
    size: {
      type: String,
      default: 'md'
    },
    variant: {
      type: String,
      default: 'default'
    },
    chevronClass: String,
  },
  computed: {
    isNoSelect() {
      return this.val === null;
    }
  },
  watch: {
    value: {
      handler(nv) {
        this.val = nv;
      },
      deep: true
    }
  },
  data() {
    return {
      val: this.value
    };
  },
  methods: {
    onChange(event, v) {
      this.$emit(event, v);
      this.val = v;
    }
  }
}
</script>

<style lang="scss" scoped>

@import "../../assets/styles/variables";

.f-control-wrapper {
  position: relative;
  border-radius: 5px;
}

.f-control {
  background-color: transparent;
  position: relative;
  z-index: 10;
  padding-right: 1.9rem;
  width: 100%;
}

.f-control-chevron {
  position: absolute;
  right: .7rem;
  top: 50%;
  transform: translateY(-50%);
  color: $inputText;
}

</style>