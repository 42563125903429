import arr from './helpers/arr';
import obj from './helpers/obj';

/**
 * Склонение числительных
 * @param number
 * @param titles
 * @return {*}
 * @example declOfNum(4, ['год', 'года', 'лет'])
 */
const declOfNum = (number, titles) => {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
};

export default {
    /**
     * Parse date like php date()
     * @param {string} format - 'd.m.Y'
     * @param {string/object/undefined} date - '2015-03-25' || new Date() - default
     * @param {bool} isStrict - default = false
     * @returns {string}
     * @todo more formats marks
     */
    date: function (format, date, isStrict) {

        if (isStrict === true && !date)
            return '';

        var d = date !== undefined ? new Date(date) : new Date();

        var f = {
            d: d.getDate() < 10 ? "0" + d.getDate() : d.getDate(),
            m: d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1,
            Y: d.getFullYear(),
            H: d.getHours() < 10 ? "0" + d.getHours() : d.getHours(),
            i: d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes(),
            s: d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds()
        };

        var parse = function () {
            if (f[arguments[0]])
                return f[arguments[0]];
        };

        return format.replace(/(d+)|(m+)|(Y+)|(H+)|(i+)|(s+)/g, parse);
    },
    /**
     * @param string|Date date
     * @return Date|null
     */
    dateParse: function (date) {

        if (date instanceof Date) {
            return date;
        }

        if (!date || typeof date !== "string") {
            return null;
        }

        // ru format
        let regDate = /^\d{2}\.\d{2}\.\d{4}$/;
        if (regDate.test(date)) {
            let p = date.split('.');
            return new Date(`${p[2]}-${p[1]}-${p[0]}`);
        }

        return new Date(Date.parse(date));
    },
    arr,
    obj,
    num: {
        /**
         * Number format
         * @param {int/string} n - value
         * @param {int} dl - length of decimal
         * @param {string} sd - sections delimiter
         * @param {string} dd - decimal delimiter
         * @returns {string}
         */
        numberFormat: function (n, dl, sd, dd) {
            if (isNaN(Number(n)) || n === null)
                return n;

            dl = isNaN(Number(dl)) ? 0 : Number(dl);
            sd = typeof sd === 'undefined' ? ' ' : sd;
            dd = typeof dd === 'undefined' ? '.' : dd;
            n = Number(n).toFixed(dl).replace(/./g, function (c, i, a) {
                return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? sd + c : c;
            });

            return dd !== '.' ? n.replace('.', dd) : n;
        },
        /**
         * decorate file size
         * @see http://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable
         * @param {int} bytes
         * @param {bool} si
         * @returns {String}
         */
        humanFileSize: function (bytes, si) {
            var thresh = si ? 1000 : 1024;
            if (Math.abs(bytes) < thresh) {
                return bytes + ' B';
            }
            var units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
            var u = -1;
            do {
                bytes /= thresh;
                ++u;
            } while (Math.abs(bytes) >= thresh && u < units.length - 1);
            return bytes.toFixed(1) + ' ' + units[u];
        },
        isInt: function (n) {
            return parseInt(n) === n;
        },
        parseFloat(v) {
            let res = parseFloat(String(v).trim().split(' ').join(''));
            return isNaN(res) ? .0 : res;
        },
    },
    formDataMake(data) {
        let d = new FormData();

        for (var k in data) {
            d.append(k, data[k]);
        }

        return d;
    },
    str: {
        /**
         * Get month num
         * @param {string} month
         * @returns {Number|null}
         */
        ruIndexByMonth(month) {
            let m = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

            let result = null;

            m.forEach((it, index) => {
                if (it.toLowerCase() === String(month).toLowerCase()) {
                    result = index + 1;
                }
            });

            return result;
        },
        /**
         * Get date from ru month
         * @param {string} month - Май 2018
         * @returns {Date|null}
         */
        ru_month_to_date(month) {

            let result = String(month).match(/^([А-Яа-я]+)[+|\s](\d{4})$/u);
            let m = this.ruIndexByMonth(result[1]);
            let y = result[2];
            if (m && y) {
                // empty
            }

            return m && y ? new Date(y + '-' + (m < 10 ? '0' + m : m) + '-01') : null;
        },
        toClipboard(str, msg) {

            if (!str) {
                return console.error('data-value not found');
            }
            let el = document.createElement('textarea');
            el.value = str;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            alert(msg || 'Скопировано в буфер обмена');
        },

        /**
         * dashed-case to camelCase
         * @param {string} str
         * @returns {string}
         */
        dashedCaseToCamel(str) {

            if (!str) {
                return str;
            }

            str = String(str)
                .split('-')
                .filter(s => !!s)
                .map(s => s[0] ? s[0].toUpperCase() + s.slice(1) : s)
                .join('');

            return String(str[0]).toLowerCase() + str.slice(1);
        },
        ucfirst(str) {
            return String(str).charAt(0).toUpperCase() + String(str).slice(1);
        },
        isValidUrl(string) {
            try {
                new URL(string);
            } catch (_) {
                return false;
            }

            return true;
        },

        declOfNum,
        declYears(number) {
            return declOfNum(number, ['год', 'года', 'лет']);
        }
    },
    selectElContent(el) {
        if (document.body.createTextRange) {
            const range = document.body.createTextRange();
            range.moveToElementText(el);
            range.select();
        } else if (window.getSelection) {
            const selection = window.getSelection();
            const range = document.createRange();
            range.selectNodeContents(el);
            selection.removeAllRanges();
            selection.addRange(range);
        } else {
            console.warn("Could not select text in node: Unsupported browser.");
        }
    },
    yearRange(qtyBefore = 2, qtyAfter = 0) {
        let y = (new Date()).getFullYear();
        let res = [];
        for (let i = qtyBefore; i > 0; i--) {
            res.push(y - i);
        }
        res.push(y);

        if (qtyAfter) {
            for (let i = 0; i <= qtyAfter; i++) {
                res.push(y + i);
            }
        }

        return res;
    },
    ruMonth() {
        return ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
            .map((name, i) => {
                return {id: i + 1, name}
            });
    },
    /**
     * Get days array between start & end
     * @param Date start
     * @param Date end
     * @returns Array
     */
    getDaysArray: function (start, end) {
        let arr = [],
            dt = new Date(start),
            endDate = new Date(end);
        for (; dt <= endDate; dt.setDate(dt.getDate() + 1)) {
            arr.push(new Date(dt));
        }
        return arr;
    },
    genId() {
        return 'id' + (new Date()).getTime() + Math.random().toString(16).slice(2);
    },
    ruDate(date) {

        let regDate = /^\d{4}-\d{2}-\d{2}$/;
        if (regDate.test(date) || date instanceof Date) {
            return this.date('d.m.Y', date);
        }

        return date;
    },
    exportToFile(data, filename, type = "text/plain;charset=utf-8") {
        let file = new Blob([data], {type: type});
        if (window.navigator.msSaveOrOpenBlob) // IE10+
            window.navigator.msSaveOrOpenBlob(file, filename);
        else { // Others
            let a = document.createElement("a"),
                url = URL.createObjectURL(file);
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            setTimeout(function () {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 0);
        }
    }
};



