export const layouts = {
    empty: 'empty',
    error: 'error',
    main: 'main',
    home: 'home',
}

export const makeSub = (hash, name, extra = {}) => ({hash, name, ...extra});

export const make = (path, name, component, meta, extra = {}) => {
    component = typeof component === 'string' ? () => import(component) : component;

    return {
        path, name, component,
        meta: {
            layout: layouts.main,
            ...meta
        }, ...extra
    };
};