import {layouts, make} from "@/router/support";

const meta = {
    section: 'home',
    layout: layouts.home,
    parent: '/register'
};

export default [
    make(
        '/register/projects',
        'Реестр проектов',
        () => import(`../views/pages/Register/RegisterList.vue`),
        {...meta}
    ),
    make(
        '/register/risks',
        'Реестр рисков',
        () => import(`../views/pages/Register/RiskList.vue`),
        {...meta}
    ),
]