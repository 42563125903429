import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import tree_list from "@/store/modules/tree_list";
import combinations from "@/store/modules/combinations";
import hb from "@/store/modules/hb";
import dash from "@/store/modules/dash";
import reports from "@/store/modules/reports";
import alerts from "@/store/modules/alerts";

export default new Vuex.Store({
    modules: {
        tree_list,
        combinations,
        hb,
        dash,
        reports,
        alerts
    }
});
