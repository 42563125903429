<template>
  <div class="layout">
    <slot/>
  </div>
</template>
<script>

export default {
  components: {},
  computed:{}
}
</script>

<style scoped>

.layout {
  position: relative;
}

</style>