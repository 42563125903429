export default {
    /**
     *
     * @param {type} arr
     * @param {type} item
     * @param {type} defaul
     * @returns {_L221.app.publ.arr.get.arr}
     */
    get: function (arr, item, defaul) {
        if (typeof arr !== 'object')
            return false;
        return arr[item] !== undefined ? arr[item] : defaul;
    },

    /**
     * Array sum
     * @param {array/object(simple)} arr
     * @returns {Number}
     */
    sum: function (arr) {
        var sum = 0;
        for (var i in arr) {
            sum += arr[i];
        }
        return sum;
    },
    /**
     * unique array
     * @param {array} a
     * @returns {array}
     */
    arrayUnique: function (a) {
        return a.reduce(function (p, c) {
            if (p.indexOf(c) < 0)
                p.push(c);
            return p;
        }, []);
    },
    /**
     * get length of array or object
     * @param {object} a
     * @returns {Number}
     */
    count: function (a) {
        a = typeof a !== 'object' ? {} : a;
        return window.$.map(a, function (n, i) {
            return i;
        }).length;
    },
    /**
     * Get key by value
     * @param {object} obj
     * @param {string} value
     * @returns {string}
     */
    getKeyByValue: function (obj, value) {
        return Object.keys(obj).filter(function (key) {
            return obj[key] === value;
        })[0];
    },
    /**
     * clone object
     * @param {mixed} obj
     * @returns {unresolved}
     */
    clone: function (obj) {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        // give temp the original obj's constructor
        var temp = typeof obj.constructor === 'function' ? obj.constructor() : {};
        for (var key in obj) {
            temp[key] = this.clone(obj[key]);
        }

        return temp;
    },
    /**
     * Exchanges all keys with their associated values in an array
     * @param {object} arr
     * @returns {object}
     */
    flip: function (arr) {
        var key, tmp_ar = {};
        for (key in arr) {
            tmp_ar[arr[key]] = key;
        }
        return tmp_ar;
    },
    /**
     * equal php array_values
     * @param {object} arr
     * @returns {Array}
     */
    values: function (arr) {
        var result = [];
        for (var k in arr) {
            result.push(arr[k]);
        }
        return result;
    },
    /**
     * Sort array of objects by property
     * @example array.sort(FlHelper.arr.dynamicSort('sorted_by'))
     * @see https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
     * @param {string} property
     * @param {string} order
     * @param {boolean} caseInsensitive
     * @return {Function}
     */
    dynamicSort: function (property, order = 'asc', caseInsensitive = true) {
        const sortOrder = order === 'desc' ? -1 : 1;

        return function (a, b) {
            let ap = typeof a[property] === 'string' && caseInsensitive ? (a[property]).toLowerCase() : a[property];
            let bp = typeof b[property] === 'string' && caseInsensitive ? (b[property]).toLowerCase() : b[property];

            // to number !
            if (typeof ap === 'number' && typeof bp !== 'number') {
                bp = 0;
            }
            if (typeof ap !== 'number' && typeof bp === 'number') {
                ap = 0;
            }

            let result = (ap < bp) ? -1 : (ap > bp) ? 1 : 0;
            return result * sortOrder;
        };
    },
    /**
     * SortList by column
     * @param {Array} list
     * @param {Column} column
     * @param {String} order
     * @returns {Array}
     */
    sortList(list, column, order) {

        let sortField = column.field;

        if (column.enum) {
            sortField = '__sortText';
            // add sort.textField
            list.forEach(it => {

                let option = Object.values(column.enum).find(o => (o[column.field] || o.id) === it[column.field]);
                it[sortField] = (option || {}).name;
            });
        }

        let toStrLower = (v) => String(v || '').toLowerCase();

        let sortOrder = order === 'desc' ? -1 : 1;

        list = list.sort((a, b) => {

            let aa = toStrLower(a[sortField]);
            let bb = toStrLower(b[sortField]);

            let result = (aa < bb) ? -1 : (aa > bb) ? 1 : 0;
            return result * sortOrder;
        });
        return list;
    },
    /**
     * Array to tree
     * @param {Array} list
     * @param {Object} settings
     * @returns {Array}
     */
    tree: function (list, settings) {
        let map = {}, node, roots = [], i;
        let s = typeof settings === 'object' ? settings : {};
        let parentKey = s.parentKey || 'parent_id';
        let childKey = s.childKey || 'children';

        for (i = 0; i < list.length; i += 1) {
            map[list[i].id] = i; // initialize the map
            list[i][childKey] = []; // initialize the children
        }

        for (i = 0; i < list.length; i += 1) {
            node = list[i];
            if (node[parentKey]) {
                // if you have dangling branches check that map[node.parentId] exists
                list[map[node[parentKey]]][childKey].push(node);
            } else {
                roots.push(node);
            }
        }
        return roots;
    },
    /**
     * Array tree to array
     * @param {Array} tree
     * @param {String} childKey
     * @returns {Array}
     */
    treeToArray(tree, childKey = 'children') {

        let list = [];

        tree.forEach(it => {
            let sub = it[childKey];
            list.push(it);
            if (Array.isArray(sub) && sub.length) {
                list = list.concat(this.treeToArray(sub, childKey));
            }
        });

        return list;
    },

    /**
     * Group an array of objects by key
     * @param {Array} arr
     * @param {String|Array} key
     * @param {String} keySeparator - for array keys, optional, default '-'
     * @returns {Object}
     */
    groupBy(arr, key, keySeparator = '-') {
        if (!Array.isArray(arr)) {
            console.log(arr);
            throw new Error('Incorrect argument "arr"!');
        }
        return arr.reduce(function (r, a) {
            let gKey = Array.isArray(key) ? key.map(k => a[k]).join(keySeparator) : a[key];
            r[gKey] = r[gKey] || [];
            r[gKey].push(a);
            return r;
        }, Object.create(null));
    },

    /**
     * Get the values of a given key.
     * @param {Array} arr
     * @param {String} value
     * @param {String} key - optional
     * @return {Array|Object}
     */
    pluck(arr, value, key) {
        if (key && typeof key === "string") {
            let res = {};
            arr.forEach(it => {
                if (typeof it[key] !== "undefined") {
                    res[it[key]] = it[value];
                }
            });
            return res;
        }
        return arr.map(it => it[value]).filter(it => typeof it !== "undefined");
    },
    move(arr, oldIndex, newIndex) {
        if (newIndex >= arr.length) {
            let k = newIndex - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
        return arr;
    },
    /**
     * Generate array of numbers
     * @param {Number} length
     * @param {Number} from - from number (default: 1)
     * @return {int[]}
     */
    generate(length, from = 1) {
        return Array.from({length}, (v, k) => from - 1 + k + 1);
    },
    /**
     * get random from list
     * @param items
     * @return {*}
     */
    random(items) {
        return items[Math.floor(Math.random()*items.length)];
    },
    /**
     * generate includes range
     * @param {int} start
     * @param {int} stop
     * @param {int} step default 1
     * @return {int[]}
     */
    range(start, stop, step = 1){
        return Array(Math.ceil((stop - start) / step + step)).fill(start).map((x, y) => x + y * step);
    }
};