<template>
  <b-form-group :label="label" class="f-group">
    <b-form-tags
        class="f-control"
        :value="value"
        @input="$emit('input',$event)"
        size="lg"
        trim
        placeholder="Введите текст"
        v-bind="control"
    ></b-form-tags>
  </b-form-group>
</template>

<script>
export default {
  props: {
    value: {
      type: [Array],
      default: null
    },
    label: String,
    control: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
}
</script>

<style scoped>

</style>