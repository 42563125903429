import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import {routes} from "./RoutesList";

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: function(to) {
        if (to.hash) {
            return {selector: to.hash}
            //Or for Vue 3:
            //return {el: to.hash}
        } else {
            return { x: 0, y: 0 }
        }
    },
    routes
})

export default router
