export default {
    props: ['field', 'errors'],
    computed: {
        fieldErrors() {
            if (!this.errors) {
                return [];
            }

            if (typeof this.errors !== 'object') {
                console.errors('Incorrect type of errors.', this.errors);
                return [];
            }

            let err = this.errors[this.field] || [];
            return Array.isArray(err) ? err : (err ? [err] : []);
        }
    }
}

