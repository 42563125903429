<template>
  <b-form-group :label="label" class="f-group">
    <b-form-textarea
                  class="f-control"
                  :value="value"
                  @input="$emit('input',$event)"
                  size="lg"
                  trim
                  placeholder="Введите текст"
                  v-bind="control"
    />
  </b-form-group>
</template>
<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    label: String,
    control: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
}
</script>
<style scoped lang="scss">

</style>