<template>
  <b-form-group :label="label" class="f-group">
    <div class="position-relative">
      <b-form-input
          class="f-control"
          :class="['input-' + size]"
          v-model="val"
          @input="onChange"
          :size="size"
          trim
          :placeholder="placeholderCur"
          :number="control.number || false"
          :type="typeCur"
          v-bind="control"
      />
      <slot name="after"/>
    </div>
  </b-form-group>
</template>
<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    label: String,
    control: {
      type: Object,
      default: () => {
        return {};
      }
    },
    placeholder: String,
    type: String,
    size: {
      type: String,
      default: 'lg',
    }
  },
  data() {
    return {
      val: this.value
    };
  },
  watch: {
    value: {
      handler(nv) {
        this.val = nv;
      },
      deep: true,
    }
  },
  methods: {
    onChange() {
      this.$emit('input', this.val);
    }
  },
  computed: {
    placeholderCur() {
      return this.placeholder || this.control.placeholder || (this.typeCur === 'number' ? 'Введите значение' : 'Введите текст');
    },
    typeCur() {
      return this.type || this.control.type || 'text'
    }
  },
  created() {
    // console.log(this.control.number, this.label);
  }
}
</script>
<style scoped lang="scss">

</style>