<template>
  <div class="form-group f-group">
    <legend class="bv-no-focus-ring col-form-label pt-0" v-if="label">{{ label }}</legend>
    <div class="f-control f-control-image input-lg" :class="[controlClass]">
      <div class="placeholder">{{ placeholder }}</div>
      <div class="preview" v-if="imgUrl" :style="{backgroundImage: 'url(' + imgUrl + ')'}"></div>
      <b-form-file accept="image/*" v-bind="control" @change="onFileChange"></b-form-file>
    </div>

  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: [File, String],
      default: null
    },
    label: String,
    placeholder: {
      type: String,
      default: 'Выберите изображение'
    },
    control: {
      type: Object,
      default: () => {
        return {};
      }
    },
    controlClass: String
  },
  watch: {
    value: {
      handler(nv) {
        this.init(nv);
      },
      deep: true
    }
  },
  data() {
    return {
      imgUrl: null
    };
  },
  methods: {
    init(nv) {
        this.imgUrl = typeof nv === File ? URL.createObjectURL(nv) : nv;
    },
    onFileChange(e) {
      const file = e.target.files[0];

      this.imgUrl = file ? URL.createObjectURL(file) : null;

      // console.log(this.imgUrl);

      this.$emit('input', file);
      this.$emit('change', e);
    }
  },
  created() {
    this.init(this.value);
  }
}
</script>
<style lang="scss">

.f-control-image {
  position: relative;

  .placeholder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .preview {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .custom-file,
  .custom-file-input {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: 500;
  }
}

</style>