<template>
  <b-form-group :label="label" class="f-group">
    <date-picker
        class="d-block w-100 f-control f-control-datapicker input-lg"
        :value="val"
        @input="$emit('input', $event)"
        @change="$emit('change', $event)"
        :format="format"
        valueType="format"
        :type="type"
        :placeholder="placeholder"></date-picker>
  </b-form-group>
</template>
<script>

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';
import decorator from "../../libs/decorator";

export default {
  props: {
    value: {
      type: [String, Number, Date],
      default: null
    },
    label: String,
    control: {
      type: Object,
      default: () => {
        return {};
      }
    },
    type: {
      type: String,
      default: 'date'
    },
    format: {
      type:String,
      default: 'DD.MM.YYYY'
    }
  },
  components: {DatePicker},
  data(){
    return {
      val: this.value
    }
  },
  watch: {
    value: {
      handler(nv){
        this.val = this.parseValue(nv);
      },
      deep: true
    }
  },
  computed: {
    placeholder(){
      return this.control.placeholder || this.label;
    }
  },
  methods: {
    parseValue(nv) {
      return typeof nv === Date ? decorator.date('d.m.Y', nv) :
          (typeof nv === "number" ? String(nv) : nv);
    }
  },
  created() {
    this.val = this.parseValue(this.value)
  }
}
</script>
<style lang="scss">

@import "../../assets/styles/variables";

.f-control-datapicker {
  border-width: 1px;
  border-style: solid;

  .mx-input-wrapper {
    min-height: 48px;
  }

  .mx-input {
    height: 100%;
    background-color: transparent;
    border: none;
    min-height: inherit;
    padding: 0.5rem 1rem;
    color: $black;

    &::placeholder {
      color: $controlColor;
    }
  }
}

</style>