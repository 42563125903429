export default {
    /**
     * Object except
     * @param {Object} obj
     * @param {Array} arrExept
     * @returns {Object}
     *
     * @example .except({name: 'test', val: 123}, ['val']); // Object {name: "test"}
     */
    except: function (obj, arrExept) {

        if (typeof obj !== 'object' || typeof arrExept !== 'object') {
            throw Error('Arguments must be objects or arrays!');
        }

        let o = Object.assign({}, obj);

        for (let k in arrExept) {
            delete o[arrExept[k]];
        }
        return o;
    },
    /**
     * Casting data (change data items or its types)
     * Will changed only defined fields. Method not change object structure!
     * @param {object} data - data to casting
     * @param {object} rules - cast rules (field: function || 'stringFunctionName:defaultValue')
     * @example data: {id: '123'}; rules: {id: parseInt}
     * @example data: {param: null}; rules: {param: 'parseInt:0'}
     * @example data: {str_param: null}; rules: {str_param: 'parseInt:"0"'}
     * @todo tests
     * @returns {object}
     */
    casting: function (data, rules) {

        let rule;

        let d = Object.assign({}, data);

        for (var k in rules) {

            let defaultValue;

            rule = rules[k];

            if (typeof rule === 'string') {

                let r = rule.split(':');

                rule = window[r[0]];
                defaultValue = r[1];
            }

            // only defined fields!!!
            if (typeof rule === 'function' && typeof d[k] !== 'undefined') {

                if (defaultValue) {
                    try {
                        defaultValue = eval(defaultValue);
                    } catch (e) {
                        console.error(e);
                    }
                }

                d[k] = rule(d[k] || defaultValue);
            }
        }

        return d;
    },
    get: function (obj, key, def) {
        if (key.includes('.')) {
            try {
                let res = eval('obj.' + key);
                return typeof res === 'undefined' ? def : res;
            } catch (e) {
                return def;
            }
        }

        let res = obj[key];
        return typeof res === 'undefined' ? def : res
    },
    clone(obj) {
        return JSON.parse(JSON.stringify(obj));
    },
    unTree(treeList, childKey, parentField = 'id', parentKey = 'parent_id') {
        let list = [];
        if (!Array.isArray(treeList)) {
            return list
        }
        const un = (item, parentId = 0) => {
            if (Array.isArray(item[childKey])) {
                item[childKey].forEach(it => un(it, item[parentField]));
            }
            delete (item[childKey]);
            item[parentKey] = parentId;
            list.push(item);
        }

        this.clone(treeList).forEach(it => un(it));

        return list;
    }
};