<template>
  <div class="layout-empty">
    <slot/>
  </div>
</template>
<script>
export default {
  components: {}
}
</script>
<style scoped>

</style>