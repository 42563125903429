<template>
  <div class="form-group f-group">
    <legend class="bv-no-focus-ring col-form-label pt-0" v-if="label">{{ label }}</legend>

    <b-form-file style="display: none;" ref="fileInput" @change="onFileChange"/>
    <div class="d-flex justify-content-start align-items-center">
      <div @click="onOpen">
        <b-alert show :variant="hasError ? 'danger' : 'light'"
                 class="d-flex justify-content-between m-0 mr-3 cursor-pointer"
                 style="padding: 0.5rem 1.25rem;">
          <div>
            <b-icon-info-circle-fill class="mr-3" v-if="hasError"/>
            <b-icon-check-circle-fill class="text-success mr-3" v-if="fileName && !hasError"/>
            <span class="text-black">{{ fileName ? fileName : currentPlaceholder }}</span>
            <b-icon-upload v-if="!fileName" class="ml-3"/>
          </div>
          <div class="cursor-pointer ml-3" v-if="fileName" @click.prevent="clear">
            <b-icon-trash/>
          </div>
        </b-alert>
      </div>
      <div v-if="fileName" class="cursor-pointer" @click="onOpen" style="color: #6B8191">
        <span class="mr-2">Заменить документ</span>
        <b-icon-upload/>
      </div>
    </div>
    <div class="f-error" v-if="hasError">{{ errorMessage }}</div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: File,
      default: null
    },
    label: String,
    placeholder: {
      type: String,
      default: 'Выберите файл'
    },
    placeholderOther: {
      type: String,
      default: 'Загрузить другой файл'
    },
    control: {
      type: Object,
      default: () => {
        return {};
      }
    },
    controlClass: String,
    hasError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: 'Ошибка документа. Проверьте файл или загрузите другой.'
    }
  },
  data() {
    return {
      fileName: null
    };
  },
  computed: {
    currentPlaceholder() {
      return this.fileName ? this.placeholderOther : this.placeholder;
    }
  },
  watch: {
    value: {
      handler(file) {
        this.fileName = file ? file.name : null
      },
      deep: true,
    }
  },
  methods: {
    onOpen() {
      this.$refs.fileInput.$refs.input.click();
    },
    onFileChange(e) {
      const file = e.target.files[0];

      this.fileName = file ? file.name : null;

      // console.log(file);

      this.$emit('input', file);
      this.$emit('change', file);
    },
    clear() {
      this.fileName = null;
      this.$refs.fileInput.reset();
      this.$emit('input', null)
      this.$emit('change', null)

      // console.log(this.file);
    }
  },
  created() {
    this.fileName = this.value?.name || null;
  }
}
</script>
<style lang="scss">
@import "../../assets/styles/variables";

.f-control-file {
  position: relative;
  padding: 1rem;
  text-align: center;
  border: 1px dashed rgba(101, 123, 154, 0.3);


  .placeholder {
    //position: absolute;
    //top: 50%;
    //left: 50%;
    //transform: translate(-50%, -50%);

  }

  .current-file-clear {
    cursor: pointer;

  }

  .current-file {
    position: relative;
    display: flex;
    justify-content: space-between;
    justify-items: center;
    background: $gray;
    color: $inputText;
    padding: .5rem 1rem;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: .5rem;
    z-index: 700;

    .b-icon {
      font-size: 20px;
    }
  }

  .custom-file,
  .custom-file-input {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: 500;
    cursor: pointer;
  }
}

</style>