<template>
  <div class="td-editable" data-value="val" @click="isEdit = true" v-click-outside="blur">
    <b-form-input
        ref="control"
        type="number"
        number
        v-model="val"
        class="td-editable-value"
        :class="[className, {'td-editable-hidden': !isEdit}]"
        :style="{minWidth}"
        :step="step"
        autocomplete="off"
        @input="change"
        @blur.native="blur"></b-form-input>
    <div class="td-editable-formatted"
         ref="preview"
         :class="[className, {'td-editable-hidden': isEdit}]"
    >{{ decorated }}
    </div>
  </div>
</template>

<script>

import decorator from "../../libs/decorator";

export default {
  props: {
    value: [String, Number],
    decorator: {
      type: String,
      default: 'money'
    },
    step: {
      type: String,
      default: '.01'
    },
    className: String
  },
  data() {
    return {
      val: this.value,
      isEdit: false,
      minWidth: 'auto',
    };
  },
  watch: {
    value: {
      handler(nv) {
        this.val = nv;
      },
      deep: true
    }
  },
  computed: {
    decorated() {
      return decorator.decorate(this.val, this.decorator);
    }
  },
  methods: {
    blur() {
      this.isEdit = false;
      this.refreshWidth();
    },
    change() {
      this.$emit('input', this.val);
      this.refreshWidth();
    },
    refreshWidth() {
      if (!this.$refs.preview) {
        return;
      }
      this.minWidth = this.$refs.preview.offsetWidth + 'px';
    }
  },
  created() {
    this.$nextTick(() => {
      this.refreshWidth();
    })


  }
}
</script>

<style lang="scss">

.td-editable {
  height: 100%;
  position: relative;
}

.td-editable-value {
  border: none;
  background: transparent;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  &[type=number] {
    -moz-appearance: textfield; /* Firefox */
  }
}

.td-editable-value,
.td-editable-formatted {
  padding: 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
}

.td-editable-hidden {
  opacity: 0;
  position: absolute;
  width: 100%;
}

</style>