<template>
  <div class="page-content-settings justify-content-between flex-wrap">
    <div class="pcs-item justify-content-start">
      <div class="text-bolder">Сортировать</div>
      <div style="width: 248px;">
        <select-custom
            :options="sortFields" text-field="label" value-field="key"
            v-model="order.field"
            @input="sort"
            variant="white"
        ></select-custom>
      </div>
    </div>

    <div>
      <b-button-group>
        <b-button variant="default" @click="changeOrder( 'desc')"
                  :class="{'active': orderDesc}">По убыванию
        </b-button>
        <b-button variant="default" @click="changeOrder('asc')"
                  :class="{'active': orderAsc}">По возрастанию
        </b-button>
      </b-button-group>
    </div>

    <div class="pcs-item justify-content-end">
      <div style="width: 180px;" v-if="viewType === 'list'">
        <select-custom-checkbox
            :options="viewFields"
            text-field="label"
            value-field="key"
            v-model="visibleFields"
            @input="onChangeFields"
            variant="white"
            placeholder="Список полей"
        ></select-custom-checkbox>
      </div>
      <div v-if="isViewType">
        <div>
          <b-button variant="default" @click="changeViewType('list')"
                    class="btn-icon text-secondary"
                    :class="{'active': currentViewType === 'list'}">
            <rl-icon icon="list" title="В виде таблицы"/>
          </b-button>
        </div>
        <div>
          <b-button variant="default" @click="changeViewType('card')"
                    class="btn-icon text-secondary"
                    :class="{'active': currentViewType === 'card'}">
            <rl-icon icon="card" title="В виде карточек"/>
          </b-button>
        </div>
      </div>
      <slot name="last"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageListSettings",
  props: {
    viewFields: {
      type: Array,  // of Column
      default: () => []
    },
    viewFieldsValue: {
      type: Array,  // of keys
      default: () => []
    },
    sortFields:  {
      type: Array,  // of Column
      default: () => []
    },
    viewType: {
      type: String,
      default: 'list'
    },
    orderField: String,
    orderDirection: {
      type: String,
      default: 'asc'
    },
    isViewType: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      order: {
        field: null,
        direction: null
      },
      currentViewType: this.viewType,
      visibleFields: [],
    }
  },
  computed: {
    orderDesc() {
      return this.order.direction === 'desc';
    },
    orderAsc() {
      return this.order.direction === 'asc';
    }
  },
  methods: {
    changeViewType(t) {
      this.currentViewType = t;
      this.$emit('changeViewType', this.currentViewType);
    },
    sort() {
      this.$emit('sort', this.order);
      console.log(this.order);
    },
    changeOrder(d) {
      this.$set(this.order, 'direction', d);
      this.sort();
    },
    onChangeFields() {
      this.$emit('changeFields', this.viewFields.filter(f => this.visibleFields.includes(f.key)));
    }
  },
  created() {
    this.visibleFields = this.viewFieldsValue.length ? this.viewFieldsValue : this.viewFields.map(f => f.key);
    this.order.field = this.orderField || (this.sortFields[0] || {}).key || null;
    this.order.direction = this.orderDirection || 'asc'
  }
}
</script>

<style scoped lang="scss">

@import "../assets/styles/variables";

.flex-wrap {
  flex-wrap: wrap;
}

.btn-icon {

  &.active {
    color: $white !important;
  }
}

</style>