export class MapCheckBox {
    constructor(id, name, color, polyline = null) {
        Object.assign(this, {id, name, color, polyline});
    }
}

export const mapCheckboxes = [
    new MapCheckBox('iprojects', 'Участки с инвестпроектами', '#000000'),
    new MapCheckBox('bottlenecks', 'Участки с узкими местами', '#D0342A'),
    new MapCheckBox('sprojects', 'Участки с расшитыми узкими местами', '#27AE60'),
    new MapCheckBox('renovation', 'Участки с ремонтами', '#5994C4', {
        weight: 6
    }),
]

export class CombinationSection {
    constructor(getAction, uploadAction, resKey = null) {
        Object.assign(this, {
            data: [],
            file: null,
            hasError: false,
            errors: [],
            uploadAction,
            getAction,
            isLoading: false,
            resKey,
            source: {},
        })
    }

    with(key, val) {
        this[key] = val;
        return this;
    }
}

export const pricesIndex = [
    {id: false, name: 'Индекс потребительских цен'},
    {id: true, name: 'Индекс цен производителей'},
];

export class ItemFactorValue {
    constructor(value, factor, cumulative, year) {
        this.baseValue = value;
        this.factor = factor;
        this.cumulative = cumulative;
        this.year = parseInt(year);
        this.open = false;
        this.refreshValue();
    }

    refreshValue() {
        // новое = базовое * (factor % +100)* (cumulative % + 100)/ 10000
        const v = this.baseValue * (this.factor + 100) * (this.cumulative + 100) / 10000;
        this.value = Math.round(v * 10000) / 10000;
        return this;
    }
}

export const dateRangeSelectOptions = [
    {id: [2020, 2024], name: '2020-2024'},
    {id: [2025, 2030], name: '2025-2030'},
    {id: [2031, 2035], name: '2031-2035'},
];

export const individualMap = [
    new MapCheckBox('180', '180 млн. тонн'),
    new MapCheckBox('197', '197 млн. тонн'),
    new MapCheckBox('210', '210 млн. тонн'),
    new MapCheckBox('240', '240 млн. тонн'),
]

export const compareMode = [
    new MapCheckBox(true, 'Режим сравнения'),
]

export const individualLegends = [
    new MapCheckBox('electro', 'Электрификация участков'),
    new MapCheckBox('no-electro', 'Без электрификации участков'),
]

export const structure = [
    new MapCheckBox('without', 'Без замещения'),
    new MapCheckBox('rc7', 'Замещение 7 млн тонн угля'),
    new MapCheckBox('rc20', 'Замещение 20 млн тонн угля'),
    new MapCheckBox('rc30', 'Замещение 30 млн тонн угля'),
]

export const gos = [
    new MapCheckBox('pt', 'Льготы по налогу на имущество 0,4%'),
    new MapCheckBox('df', 'Отмена акциза на дизельное топливо'),
    new MapCheckBox('crf', 'Отмена понижающих коэффициентов к провозным платам на перевозку угля'),
    new MapCheckBox('fnb', 'Использование средств ФНБ - 188 млрд. руб'),
    new MapCheckBox('fb', 'Использование средств Федерального бюджета для окупаемости проекта'),
]
