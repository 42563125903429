// import {access, isAccessTo} from '@/router/access';
import decorator from "@/libs/decorator";
import {dateRangeSelectOptions} from "@/data/hb";
const loc = window.location.origin;


export default {
    install(Vue) {
        Vue.prototype.$apiPath = this.apiPath();
        Vue.prototype.$decorator = decorator;
        Vue.prototype.$dateRangeSelectOptions = dateRangeSelectOptions;
        // Vue.prototype.$access = access;
        // Vue.prototype.$isAccessTo = isAccessTo;
    },
    apiPath() {
        return process.env.VUE_APP_API_PATH ? process.env.VUE_APP_API_PATH : `${loc}/api`;
    }
}
