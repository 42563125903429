<template>
  <b-form-group :label="label" class="f-group f-group-info">
    <div class="f-info">{{ value }}</div>
  </b-form-group>
</template>

<script>
export default {
  name: "Info",
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    label: String,
  }
}
</script>

<style scoped>

</style>