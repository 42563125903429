<template>
  <div class="alert-wrapper">
    <div
      v-for="msg in alerts"
      :key="msg.text"
      class="alert-item"
    >
      <div
        class="alert-message"
        :class="{
          'alert-success': msg.type === 'success',
          'alert-error': msg.type === 'error',
          'alert-info': msg.type === 'info'
        }"
      >
        {{ msg.text }}
      </div>

      <i
        class="alert-icon"
        :style="{
          backgroundSize: 'cover'
        }"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'CAlert',
  computed: {
    ...mapGetters('alerts', ['alerts']),
  },
};
</script>

<style lang="scss" scoped>
.alert-wrapper {
  position: absolute;
  max-width: 60%;
  left: 50%;
  bottom: 2%;
  z-index: 50;
  transform: translateX(-50%);
}

.alert-item {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 16px;
}

.alert-message {
  padding: 12px 16px;
  color: #fff;
  border-radius: 8px;
  text-align: center;
}

.alert-success {
  background: #2ED47A;
}

.alert-error {
  background: #E72A36;
}

.alert-info {
  background: #5994C4;
}

.alert-icon {
  display: block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin-left: 6px;
  background: url('@/assets/alerts/info.svg') center center no-repeat;
}
</style>
