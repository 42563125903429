import Vue from 'vue'
import store from './store'

import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

import settings from './settings';
Vue.use(settings);

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

import vClickOutside from 'v-click-outside'
Vue.use(vClickOutside)

import 'devextreme/dist/css/dx.light.css';
import '@/assets/styles/main.scss'

import vSelect from 'vue-select'

Vue.component('v-select', vSelect)
Vue.component('empty-layout', require( '@/views/layouts/Empty.vue').default)
Vue.component('error-layout', require( '@/views/layouts/Error.vue').default)
Vue.component('main-layout', require( '@/views/layouts/Main.vue').default)
Vue.component('home-layout', require( '@/views/layouts/Home.vue').default)
Vue.component('rl-icon', require( './components/IconBase.vue').default);

Vue.component('f-input-text', require( './components/filters/InputText.vue').default);
Vue.component('f-textarea', require( './components/filters/Textarea.vue').default);
Vue.component('f-date', require( './components/filters/InputDate.vue').default);
Vue.component('f-input-image', require( './components/filters/InputImage.vue').default);
Vue.component('f-input-file', require( './components/filters/InputFile.vue').default);
Vue.component('f-input-year', require( './components/form/InputYear.vue').default);
Vue.component('select-custom', require( './components/form/Select.vue').default);
Vue.component('select-custom-checkbox', require( './components/form/SelectCheckbox.vue').default);
Vue.component('f-select', require( './components/filters/Select.vue').default);
Vue.component('f-select-multi', require( './components/filters/SelectMulti.vue').default);
Vue.component('f-tags', require( './components/filters/Tags.vue').default);
Vue.component('f-year-range', require( './components/filters/InputYearRange.vue').default);
Vue.component('f-img', require( './components/Image.vue').default);
Vue.component('f-info', require( './components/filters/Info.vue').default);
Vue.component('f-box', require( './components/Box.vue').default);
Vue.component('card-collapse', require( './components/CardCollapse.vue').default);
Vue.component('td-editable', require( './components/form/CellInputNumber.vue').default);
Vue.component('page-list-settings', require( './components/PageListSettings.vue').default);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
