export default {
    namespaced: true,
    state: {
        opened: {}
    },
    mutations: {
        registerToggle(state, {item, isOpen}){
            state.opened[item.id] = isOpen;
            // console.log(state.opened);
        }
    },
    getters: {
        getOpened(state) {
            return state.opened;
        }
    }
}

