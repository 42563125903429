import axios from "axios";
import helper from "../../libs/helper";
import settings from '@/settings';

const apiPath = settings.apiPath()

/**
 *
 * @param {String} id - state key
 * @param {String} url - uri
 * @param val - default state value
 * @param {String} dataKey - response key
 * @return {{mutations: {}, state: (function(): {}), getters: {}, actions: {}, namespaced: true}}
 */
export function moduleFactory(id, url, val = null, dataKey = null) {

    const hb = {
        state: () => {

            const s = {};
            s[id] = val;
            return s;
        },
        mutations: {},
        actions: {},
        getters: {}
    };

    let idCap = helper.str.ucfirst(id);
    hb.mutations['set' + idCap] = (state, data) => {
        state[id] = data;
    };
    hb.actions['get' + idCap] = ({commit}, params) => {
        return new Promise((resolve, reject) => {
            axios.get(apiPath + url, {params})
                .then(res => {
                    commit('set' + idCap, dataKey ? res.data[dataKey] : res.data)
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                });
        });
    };
    hb.getters[id] = state => state[id];

    hb.namespaced = true;

    return hb;
}

export const makeUpload = (path) => {
    return ({commit}, {file, params}) => {

        const fd = new FormData();
        fd.append('file', file);

        const uri = eval('`' + path + '`');

        return new Promise((resolve, reject) => {
            axios.post(apiPath + uri, fd, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                });
        });
    }
}

export const makeGet = (path, dataKey, method = 'get') => {
    return ({commit}, params) => {
        let uri = eval('`' + path + '`');

        if(Object.keys(params?.qs || {}).length) {
            const qs  = new URLSearchParams(params.qs);
            uri += '?' + qs;
        }

        return new Promise((resolve, reject) => {
            (params?.payload ? axios[method](apiPath + uri,{ params: params?.payload }) : axios[method](apiPath + uri))
                .then(res => {
                    console.log(res);
                    resolve(dataKey ? res.data[dataKey] || null : res.data)
                })
                .catch(err => {
                    reject(err)
                });
        });

    }
}

export const patch = (path) => {
    return makeGet(path, null, 'patch')
}

export const post = (path) => {
    return makeGet(path, null, 'post')
}

export const makeDownloadExcel = (path, dataKey, method = 'get') => {
    return ({commit}, params) => {

        let uri = eval('`' + path + '`');

        if(Object.keys(params?.qs || {}).length) {
            const qs  = new URLSearchParams(params.qs);
            uri += '?' + qs;
        }

        return new Promise((resolve, reject) => {
            axios.get(apiPath + uri, {
                responseType: 'blob'
            }).then((response) => {
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute(
                    'download',
                    `Отчет-${new Date().toLocaleDateString()}.xlsx`
                )
                document.body.appendChild(link)
                link.click()
            })
        });

    }
}

export const makeEmptyModule = () => ({
    state: () => ({}),
    mutations: {},
    actions: {},
    getters: {},
    namespaced: true,
});
