<template>
  <div class="layout">
    <div class="layout-wrapper">
      <SideBarNav :nav="nav"/>
      <div class="layout-content">
        <slot/>
      </div>
    </div>
  </div>

</template>

<script>

import SideBarNav from "../../components/SideBarNav";

export default {
  name: "Main",
  components: {
    SideBarNav,
  },
  data() {
    return {};
  },
  computed: {
    nav() {
      // console.log(this.$router.options.routes);

      const list = [
          ...this.$router.options.routes
              .filter(r => (r.meta || {}).isMainNav)
              .map(r => {
                r.meta.isExactActive =
                    r.path === this.$route.path ||
                    r.path === (this.$route.meta || {}).parentPath
                return JSON.parse(JSON.stringify(r));
              })
      ];

      // console.log(list);

      return list;
    }
  },
  mounted() {
    document.title = 'ЦМИП РЖД'
  }
}
</script>

<style scoped>

.layout-wrapper {
  display: flex;
}

.layout-content {
  width: 100%;
}

</style>