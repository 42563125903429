import {layouts, make} from './support';

const homeMeta = {
    section: 'home',
    layout: layouts.home,
};

// const mapVersions = [1, 2, 3, 4, 5, 6,];
//
// const versions = mapVersions.map(
    //     it => make(
        //         '/version/' + it,
        //         'Версия ' + it,
        //         () => import(`../views/pages/maps/MapPage${it}.vue`),
        //         mapMeta
        //     )
        // );

import dash from "./dash";
import reestrs from './reestrs';

const subNavs = [
    make(
        '/modeling/modeling-one',
        'Тестовый пример',
        () => import(`../views/pages/modeling/FreightTransportation.vue`),
        {...homeMeta, parent: '/modeling'}
    ),
    make(
        '/modeling/modeling-two',
        'Направление моделирования 2 ',
        () => import(`../views/pages/modeling/PassengerTransportation.vue`),
        {...homeMeta, parent: '/modeling'}
    ),
    make(
        '/modeling/modeling-three',
        'Направление моделирования 3',
        () => import(`../views/pages/modeling/ModelingInfrastructure.vue`),
        {...homeMeta, parent: '/modeling'}
    ),
];

export const mainNav = [
    make(
        '/',
        'Главная',
        () => import(`../views/pages/modeling/ModelingMain.vue`),
        {...homeMeta, isMainNav: true, icon: 'Build'}
    ),
    make(
        '/hb',
        'ГИС',
        () => import(`../views/pages/Hb.vue`),
        {...homeMeta, isMainNav: true, icon: 'Book'}
    ),
    make(
        '/register',
        'Реестры',
        () => import(`../views/pages/Reestrs.vue`),
        { ...homeMeta, isMainNav: true, icon: 'Database', sub: reestrs }
    ),
    make(
        '/dash',
        'Дашборды',
        () => import(`../views/pages/Dash.vue`),
        {...homeMeta, isMainNav: true, icon: 'Dash', sub: dash}
    ),
    make(
        '/reports',
        'Отчеты',
        () => import(`../views/pages/Reports.vue`),
        {...homeMeta, isMainNav: true, icon: 'Report'}
    ),
    // make(
    //     '/about',
    //     'О системе',
    //     () => import(`../views/pages/About.vue`),
    //     {...homeMeta, isMainNav: true, icon: 'Info'}
    // )
];

export const routes = [
    ...mainNav,
    ...subNavs,
    ...dash,
    ...reestrs,
    make(
        '/register/risks/create',
        'Создание риска',
        () => import(`../views/pages/Register/RiskForm.vue`),
        {...homeMeta}
    ),
    make(
        '/register/risks/edit/:id',
        'Редактирование риска',
        () => import(`../views/pages/Register/RiskForm.vue`),
        {...homeMeta}
    ),
    make(
        '/register/project/create',
        'Создание проекта',
        () => import(`../views/pages/Register/RegisterForm.vue`),
        {...homeMeta}
    ),
    make(
        '/register/project/edit/:id',
        'Редактирование проекта',
        () => import(`../views/pages/Register/RegisterForm.vue`),
        {...homeMeta, 'parent': '/register'}
    ),

    make(
        '/modeling/expansion/:id',
        'expansionItem ',
        () => import(`../views/pages/modeling/ModelingExpansionItem.vue`),
        {...homeMeta, parent: '/modeling/expansion'}
    ),
    make(
        '/modeling/optimization/:id',
        'optimizationItem ',
        () => import(`../views/pages/modeling/ModelingOptimizationItem.vue`),
        {...homeMeta, parent: '/modeling/optimization'}
    ),
    make(
        '/modeling/optimization/:id/stable',
        'optimizationItemStab',
        () => import(`../views/pages/modeling/ModelingOptimizationItemStable.vue`),
        {...homeMeta, parent: '/modeling/optimization'}
    ),
    make(
        '/modeling/grade',
        'Оценка экономической эффективности ИП и программы ремонтов',
        () => import(`../views/pages/modeling/ModelingGrade.vue`),
        {...homeMeta, parent: '/modeling'}
    ),
    make(
        '/modeling/grade/:id',
        'gradeItem',
        () => import(`../views/pages/modeling/ModelingGradeItem.vue`),
        {...homeMeta, parent: '/modeling/grade'}
    ),
    make(
        '/modeling/expansion',
        'Ликвидация узких мест',
        () => import(`../views/pages/modeling/ModelingExpansion.vue`),
        {...homeMeta, parent: '/modeling'}
    ),
    make(
        '/modeling/optimization',
        'Оптимизация инвестпроектов в инвестиционной программе',
        () => import(`../views/pages/modeling/ModelingOptimization.vue`),
        {...homeMeta, parent: '/modeling'}
    ),

    // tests
    make(
        '/tests/register/costs',
        'RegisterCostsTest',
        () => import(`../views/tests/RegisterCostsTest.vue`),
        {...homeMeta, parent: '/tests'}
    ),
    make(
        '/modeling/passenger-transportation/center',
        'Центральный транспортный узел',
        () => import(`../views/pages/modeling/ModelingPassenger.vue`),
        {...homeMeta, parent: '/modeling/passenger-transportation', imgName: 'pass1.png'}
    ),
    make(
        '/modeling/passenger-transportation/south',
        'Южный полигон',
        () => import(`../views/pages/modeling/ModelingPassenger.vue`),
        {...homeMeta, parent: '/modeling/passenger-transportation', imgName: 'pass2.png'}
    ),
    make(
        '/modeling/passenger-transportation/center-south',
        'Центр-Юг',
        () => import(`../views/pages/modeling/ModelingPassenger.vue`),
        {...homeMeta, parent: '/modeling/passenger-transportation', imgName: 'pass3.png'}
    ),
    make(
        '/modeling/infrastructure/locomotive-traction',
        'Локомотивная тяга',
        () => import(`../views/pages/modeling/ModelingPassenger.vue`),
        {...homeMeta, parent: '/modeling/infrastructure', imgName: 'infra.png'}
    ),
    make(
        '/modeling/infrastructure/energy',
        'Энергетика',
        () => import(`../views/pages/modeling/ModelingPassenger.vue`),
        {...homeMeta, parent: '/modeling/infrastructure', imgName: 'infra.png'}
    ),
    make(
        '/modeling/infrastructure/isso',
        'ИССО',
        () => import(`../views/pages/modeling/ModelingPassenger.vue`),
        {...homeMeta, parent: '/modeling/infrastructure', imgName: 'infra.png'}
        ),
    make(
        '/modeling/individual-polygons',
        'Сценарии по отдельным полигонам',
        () => import(`../views/pages/modeling/ModelingIndividual.vue`),
        {...homeMeta, parent: '/modeling/freight-transportation'}
    ),
    make(
        '/login',
        'Логин',
        () => import(`../views/pages/Login.vue`),
        {section: 'home', layout: 'empty', parent: '/'}
    ),

    {path: "/401", component: () => import('../views/pages/errors/401'), meta: {layout: layouts.error}},
    {path: "/403", component: () => import('../views/pages/errors/403'), meta: {layout: layouts.error}},
    {path: "/500", component: () => import('../views/pages/errors/500'), meta: {layout: layouts.error}},
    {path: "*", component: () => import('../views/pages/errors/404'), meta: {layout: layouts.error}},
]
