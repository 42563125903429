import {makeDownloadExcel, makeEmptyModule} from "@/store/modules/moduleFactory";
import axios from "axios";
import settings from '@/settings';

const apiPath = settings.apiPath();

const uri = '/report';
const sumUri = '/sum-report';

let module = makeEmptyModule()
const r = (action, uri, dataKey = 'entities') => ({action, uri, dataKey});

[r('getSumReport', '?is_dummy=${params.id}')].forEach(it => {
    module.actions[it.action] = makeDownloadExcel(sumUri + it.uri)
});

[r('getReport', '/${params.id}')].forEach(it => {
    module.actions[it.action] = makeDownloadExcel(uri + it.uri, it.dataKey);
});

export default module;
