import {moduleFactory, makeUpload, makeGet, patch, post} from "@/store/modules/moduleFactory";
import axios from "axios";
import settings from '@/settings';

const apiPath = settings.apiPath()

const uri = '/combination';

let module = moduleFactory('combinations', uri, [], 'entities')
module.namespaced = true;

module.actions.storeCombination = ({commit}, form) => {
    return new Promise((resolve, reject) => {
        axios.post(apiPath + uri, form)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            });
    });
}
module.actions.updateCombination = ({commit}, {id, data}) => {
    return new Promise((resolve, reject) => {
        axios.patch(apiPath + uri + '/' + id, data)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            });
    });
}
module.actions.deleteCombination = ({commit}, {id}) => {
    return new Promise((resolve, reject) => {
        axios.delete(apiPath + uri + '/' + id)
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            });
    });
}
module.actions.getCombinationHead = makeGet('/combination/${params.id}');

// invest
module.actions.uploadInvestProg = makeUpload('/upload/invest-prog/${params.id}');
module.actions.getInvestProg = makeGet('/combination/${params.id}/invest-prog?include_bottlenecks_ind=${params.bottleneck}');

// cargo
module.actions.uploadCargo = makeUpload('/upload/demand/${params.id}');
module.actions.getCargo = makeGet('/combination/${params.id}/demand');
// tariff
module.actions.uploadTariff = makeUpload('/upload/tariff-plan/${params.id}');
module.actions.getTariff = makeGet('/combination/${params.id}/tariff');
// Repair
module.actions.uploadRepair = makeUpload('/upload/repair/${params.id}');
module.actions.getRepair = makeGet('/combination/${params.id}/repair');
// Costs
module.actions.uploadCosts = makeUpload('/upload/operational-cost/${params.id}');
module.actions.getCosts = makeGet('/combination/${params.id}/cost');
// macro
module.actions.getMacro = makeGet('/combination/${params.id}/price-index');

// copy
module.actions.copyItem = post(uri + '/${params.id}/copy');

// calculate
module.actions.calculateItem = patch('/calculate/${params.id}/combination');

// graph
module.actions.getGraph = makeGet('/combination/${params.id}/graph-full', 'entities')
module.actions.getGraphModel = makeGet('/combination/${params.id}/graph-model', 'entities')
// modeling
module.actions.getBottleneck = makeGet('/calculate/${params.id}/bottleneck', 'entities')
// Найти узкие места
module.actions.calculateBottleneck = patch('/calculate/${params.id}/bottleneck-removal')
// Расшить узкие места
module.actions.expandBottleneck = patch('/calculate/${params.id}/combination')
// Сохранение индикатора включения
module.actions.saveInludeBottleneck = patch('/calculate/${params.id}/include-bottleneck')

module.actions.calculateLog = makeGet('/calculate/${params.id}/log/${params.type}', 'entities')
// получение показателей для: Формирование варианта ИП при сокращении лимита финансирования
module.actions.getInvestProgOptimization = makeGet('/calculate/${params.id}/iprog-optimization/config', 'entities')
// сохранение показателей
module.actions.saveInvestProgOptimization = patch('/calculate/${params.id}/iprog-optimization/config')
// рассчитать показатели
module.actions.calculateInvestProgOptimization = patch('/calculate/${params.id}/iprog-optimization?type_opt=${params.type_opt}&funding_limit=${params.funding_limit}')

//  Определение варианта ИП, наиболее устойчивого к сценариям спроса
module.actions.getStressTestOptimization  = makeGet('/calculate/${params.id}/stress-testing/config', 'entities')
module.actions.saveStressTestOptimization  = patch('/calculate/${params.id}/stress-testing/config')

export default module;