<template>
  <div id="app">
    <component :is="layout" v-bind="layoutProps">
      <router-view/>
    </component>
  </div>
</template>

<script>
const defaultLayout = 'empty';
export default {
  name: 'App',
  components: {},
  computed: {
    layout() {
      return (this.$route.meta.layout || defaultLayout) + '-layout';
    },
    layoutProps() {
      return this.$route.meta.layoutProps || {};
    }
  },
  mounted() {
    const exist = localStorage.getItem('login');
    if (exist !== 'performed') {
      this.$router.push('/login');
    }
  }
}
</script>
