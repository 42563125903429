<template>
  <b-img :blank="!src" class="f-img" fluid :src="src" blank-color="#F1F5FB" :alt="alt"></b-img>
</template>

<script>
export default {
  props: {
    src: String,
    alt: String,
  }
}
</script>

<style scoped>
.f-img {
  width: 100%;
  border-radius: 10px;
  max-height: 200px;
}
</style>