<template>
  <g fill="transparent">
    <path d="M17.3329 20.0008C18.8058 20.0008 19.9998 18.8067 19.9998 17.3338C19.9998 15.8609 18.8058 14.6669 17.3329 14.6669C15.86 14.6669 14.666 15.8609 14.666 17.3338C14.666 18.8067 15.86 20.0008 17.3329 20.0008Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.66691 9.33382C8.1398 9.33382 9.33382 8.1398 9.33382 6.66691C9.33382 5.19402 8.1398 4 6.66691 4C5.19402 4 4 5.19402 4 6.66691C4 8.1398 5.19402 9.33382 6.66691 9.33382Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.8887 6.6662H15.5556C16.0271 6.6662 16.4793 6.85352 16.8128 7.18695C17.1462 7.52037 17.3335 7.9726 17.3335 8.44414V14.6669" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.66504 9.33307V20.0007" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
</template>

<script>
export default {
  name: "System"
}
</script>

<style scoped>

</style>