import {makeEmptyModule, makeGet} from "@/store/modules/moduleFactory";
import axios from "axios";
import settings from '@/settings';

const apiPath = settings.apiPath()

const uri = '/dashboard';

let module = makeEmptyModule();
const r = (action, uri, dataKey = 'entities') => ({action, uri, dataKey});

[
    /* 1 */ r('getMainResults', '/${params.id}/main-results-inv-program'),
    /* 2 */ r('getDetailSingle', '/${params.id}/detailing-single-edge'),
    /* 3 */ r('getUnderDelivery', '/${params.id}/analysis-volume-reasons-underdelivery'),
    /* 4 */ r('getDynamicFin', '/${params.id}/dynamics-main-financial-indicators'),
    /* 5 */ r('getComparisonFin', '/${params.id}/comparison-financial-indicators'),
    /* 6 */ r('getDynamicNatural', '/${params.id}/dynamics-main-physical-indicators'),
    /* 7 */ r('getComparisonNatural', '/${params.id}/comparison-natural-indicators'),
    /* 8 */ r('getIndividualFin', '/${params.id}/individual-financial-indicators-v2'),
    /* 8 */ r('getIndividualNatural', '/${params.id}/individual-physical-indicators-v2')
].forEach(it => {
    module.actions[it.action] = makeGet(uri + it.uri, it.dataKey);
})

let state = module.state();
const lsKeyRegisterItem = 'register-item-dash'
state.registerItemIdForDash = parseInt(localStorage.getItem(lsKeyRegisterItem) || 0)

module.mutations.setRegisterForDash = (state, id) => {
    state.registerItemIdForDash = id;
    localStorage.setItem(lsKeyRegisterItem, id);
}
module.getters.registerItemIdForDash = state => state.registerItemIdForDash;
module.state = () => ({...state});

// console.log(module, state);

export default module;
