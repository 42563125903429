<template>    
    <div class="form-group f-group">
        <label v-if="label" class="legend">{{label}}<span v-if="required" class="text-danger ml-1">*</span></label>
        <vue-select
            class="f-control input-lg"
            :placeholder="placeholder || 'Выбрать'"
            :multiple="true"
            size="lg"
            :options="opt"
            :label="textField"
            :track-by="valueField"
            :value="value || (multiple ? [] : null )"
            :labels="selectLabels"
            :required="required"
            @input="$emit('input', $event)"
            @change="$emit('change',$event)"
            @add-tag="$emit('add-tag',$event)"
            v-bind='control'></vue-select>
        <div class="invalid-feedback d-block" v-for="(error, k) in fieldErrors" :key="k">{{ error }}</div>
    </div>
</template>
<script>

    import VueSelect from '../enso/select/VueSelect';
    import fieldErrorsMixin from './fieldErrorsMixin';

    export default {
        components: {VueSelect},
        props: ['options', 'name', 'label', 'value', 'textField', 'valueField', 'control', 'required', 'placeholder'],
        data() {
            return {
                opt: this.options || [],
                selectLabels: {
                    select: 'выбрать',
                    deselect: 'исключить',
                    noOptions: 'Не найдено',
                    noResults: 'Не найдено',
                    addTag: 'Добавить'
                }
            };
        },
        mixins: [fieldErrorsMixin],
        watch:{
            options: {
                handler(){
                    this.opt = this.options;
                },
                deep: true
            }
        },
        methods: {

        },
        mounted() {},
        created() {}
    }
</script>
<style lang="scss">

</style>