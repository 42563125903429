<template>
  <b-form-group :label="label" class="f-group">
    <select-custom
        :value="value"
        @input="$emit('input',$event)"
        @change="$emit('change',$event)"
        first
        :options="options"
        :text-field="textField"
        :value-field="valueField"
        v-bind="control"
    />
  </b-form-group>
</template>
<script>
export default {
  props: {
    value: {
      type: [String, Number, Array],
      default: null
    },
    label: String,
    options: {
      type: Array,
      default: () => []
    },
    textField: {
      type: String,
      default: 'name'
    },
    valueField: {
      type: String,
      default: 'id'
    },
    control: {
      type: Object,
      default: () => {
        return {
          size: 'lg'
        };
      }
    }
  },

}
</script>
<style scoped lang="scss">


</style>