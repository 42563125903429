<template>
  <b-form-group :label="label" class="f-group">
    <div class="yr-wrapper">
      <div class="yr-item">
        <date-picker
            class="d-block w-100 f-control f-control-datapicker input-lg"
            @change="onChange"
            @input="onChange"
            format="YYYY"
            valueType="format"
            v-model="start"
            type="year"></date-picker>
      </div>
      <div class="yr-item">
        <date-picker
            class="d-block w-100 f-control f-control-datapicker input-lg"
            @change="onChange"
            @input="onChange"
            format="YYYY"
            valueType="format"
            v-model="end"
            type="year"></date-picker>
      </div>
    </div>
  </b-form-group>
</template>
<script>

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';

export default {
  components: {DatePicker},
  props: {
    value: {
      type: Array,
    },
    label: String,
    control: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  watch: {
    value: {
      handler(nv) {
        this.init(nv);

      },
      deep: true,
    }
  },
  data() {
    return {
      start: '',
      end: '',
    }
  },
  methods: {
    init(v) {
      let arr = Array.isArray(v) ? v : [];
      // console.log(arr);
      this.$set(this, 'start', String(arr[0] || ''));
      this.end = String(arr[1] || '');
    },
    onChange() {

        this.$emit('input', this.buildVal());
        this.$emit('change', this.buildVal());

    },
    buildVal() {
      return [parseInt(this.start || 0), parseInt(this.end || 0)];
    }
  },
  created() {
    this.init(this.value)
  }
}
</script>
<style lang="scss" scoped>

@import "../../assets/styles/variables";

.yr-wrapper {
  display: flex;
  justify-content: space-between;
}

.yr-item {
  width: 49%;
  position: relative;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield; /* Firefox */
  }
}

.yr-icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 500;
}

</style>