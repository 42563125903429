<template>
  <div style="position: relative;">
    <div style="min-height: 100vh;" class="home-layout content-with-sidebar" :class="{'sidebar-open': sidebarOpen}">
      <div class="home-nav scroll-y sidebar">
        <div style="padding: 16px 10px; display: flex; position: relative;">
          <img src="../../assets/img/navlogo.png" alt="">
          <div style="font-size: 38px; letter-spacing: -0.84px; line-height: 1; color: #fff; position: absolute; left: 155px; top: 50%; transform: translateY(-33.6%);">ИСУР</div>
        </div>
        <router-link class="home-back" v-if="back" :to="back.to">
          <b-icon-chevron-left class="home-back-icon"/>
          <span>{{ back.name }}</span>
        </router-link>
        <b-nav vertical>

          <template v-for="(it, k) in nav">
            <router-link :key="k" :to="{path: it.path}" v-slot="{ href, route, navigate }">
              <b-nav-item :href="href" @click="navigate"
                          :active="it.meta.isExactActive"
                          :title="it.name"
                          class="home-nav-item">
                <div class="item-icon" v-if="route.meta.icon">
                  <rl-icon :icon="route.meta.icon" class="mr-2"/>
                </div>
                <div v-else class="mr-4"></div>
                <div class="item-name" style="width: 70%;">{{ it.name }}</div>
                <b-icon-chevron-right v-if="route.meta.sub.length && !route.meta.hideSubArrow" :class="{'rotate-right': it.meta.isOpened}"/>
              </b-nav-item>
            </router-link>

            <transition name="fade" :key=" k + 'sub'">
              <div v-if="it.meta.isOpened && sidebarOpen">
                <router-link v-for="(sit, kk) in it.meta.sub" :key="k + '-' + kk" :to="{path: sit.path}"
                             v-slot="{ href, route, navigate }">
                  <b-nav-item :href="href" @click="navigate"
                              :active="sit.meta.isExactActive"
                              :title="sit.meta.title || sit.name"
                              class="home-nav-item"
                  >
                    <div class="item-name pl-6 pr-2 text-wrap">
                      <span>{{ sit.meta.title || sit.name }}</span>
                    </div>
                  </b-nav-item>
                </router-link>
              </div>
            </transition>

          </template>
        </b-nav>
        <div
          class="logout-button"
          @click="logout"
        >
          <div v-if="sidebarOpen" class="logout-button-inside">
            <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.53125 7.71875V6.07812C8.53125 5.643 8.7041 5.2257 9.01178 4.91803C9.31945 4.61035 9.73675 4.4375 10.1719 4.4375L16.7344 4.4375C17.1695 4.4375 17.5868 4.61035 17.8945 4.91803C18.2021 5.2257 18.375 5.643 18.375 6.07812V15.9219C18.375 16.357 18.2021 16.7743 17.8945 17.082C17.5868 17.3896 17.1695 17.5625 16.7344 17.5625H10.5C9.59396 17.5625 8.53125 16.8279 8.53125 15.9219V14.2812M5.90625 7.71875L2.625 11L5.90625 14.2812M13.7812 11L3.28125 11" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span>Выйти из системы</span>
          </div>
          <div v-else class="logout-button-inside">
            <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.53125 7.71875V6.07812C8.53125 5.643 8.7041 5.2257 9.01178 4.91803C9.31945 4.61035 9.73675 4.4375 10.1719 4.4375L16.7344 4.4375C17.1695 4.4375 17.5868 4.61035 17.8945 4.91803C18.2021 5.2257 18.375 5.643 18.375 6.07812V15.9219C18.375 16.357 18.2021 16.7743 17.8945 17.082C17.5868 17.3896 17.1695 17.5625 16.7344 17.5625H10.5C9.59396 17.5625 8.53125 16.8279 8.53125 15.9219V14.2812M5.90625 7.71875L2.625 11L5.90625 14.2812M13.7812 11L3.28125 11" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
      </div>
      <div class="home-content">
        <slot/>
      </div>
    </div>
    <VAlert/>
  </div>
</template>

<script>
import {mainNav} from "../../router/RoutesList";
import VAlert from "@/components/alert/VAlert.vue";

export default {
  components: {
    VAlert
  },
  props: {
    section: {
      type: String,
      default: 'home',
    },
    back: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      sidebarOpen: true,
    };
  },
  computed: {
    nav() {

      // console.log(this.$route?.meta?.parent);

      const nav = mainNav
          .map(r => {
            r.path = r.hash ? '#' + r.hash : r.path;
            r.meta = r.meta || {};
            r.meta.sub = (r.meta.sub || []).map(s => {
              s.meta.isExactActive =
                  s.path === this.$route.path ||
                  s.path === this.$route?.meta?.parent //||
                  // s.meta?.parent === this.$route?.meta?.parent;
              // for hash
              if (s.path.charAt(0) === '#' && s.meta.isExactActive) {
                s.meta.isExactActive = window.location.hash === s.path;
              }

              return s;
            });
            r.meta.isExactActive = r.path === this.$route.path;


            if (typeof r.meta.hasOpenSub === "function") {
              r.meta.isOpened = r.meta.hasOpenSub(this.$route) || false;
            } else {
              r.meta.isOpened = r.meta.isExactActive || r.meta.sub.filter(s => s.meta.isExactActive).length > 0;
            }

            return r;
          });

      // console.log(nav);
      return nav;
    }
  },
  methods: {
    logout() {
      localStorage.setItem('login', '');
      this.$router.push('/login');
    }
  },
}
</script>

<style lang="scss" scoped>

@import "../../assets/styles/variables";

.logout-button {
  padding: 16px 20px;
  font-size: 18px;
  cursor: pointer;
  user-select: none;
  margin-top: auto;
  opacity: 0.8;
}

.logout-button-inside {
  display: flex;
  align-items: center;
  gap: 8px;
}

.logout-button:hover {
  opacity: 1;
}

.item-icon {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 22px;
  color: $inputText;
}

.item-name {
  padding-right: 5px;
}
</style>