<template>
  <div class="f-control-wrapper" :class="['f-v-' + variant]" v-click-outside="close">

    <div class="f-control input-md" @click="isOpen = !isOpen">
      <span>{{ placeholder }}</span>
      <b-icon-chevron-down class="f-control-chevron"/>
    </div>
    <div class="f-options" :class="{active: isOpen}">
      <!--      :value="value"-->
      <!--      @input="$emit('input',$event)"-->
      <b-form-checkbox-group
          v-model="val"
          :options="options"
          :text-field="textField"
          :value-field="valueField"
          @change="$emit('change',$event)"
          stacked
      ></b-form-checkbox-group>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => ([])
    },
    options: {
      type: Array,
      default: () => []
    },
    textField: {
      type: String,
      default: 'name'
    },
    valueField: {
      type: String,
      default: 'id'
    },
    control: {
      type: Object,
      default: () => {
        return {};
      }
    },
    placeholder: {
      type: String,
      default: 'Выбрать',
    },
    size: {
      type: String,
      default: 'md'
    },
    variant: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {
      isOpen: false,
      val: this.value
    }
  },
  watch: {
    value: {
      handler(nv) {
        // console.log(nv);
        this.val = nv;
      },
      deep: true
    },
    val: {
      handler(nv) {
        // console.log(nv);
        this.$emit('input', nv);
      },
      deep: true
    }
  },
  methods: {
    close() {
      this.isOpen = false
    }
  },
  created() {
    // console.log(this.val);
  }
}
</script>

<style lang="scss" scoped>

@import "../../assets/styles/variables";

.f-control-wrapper {
  position: relative;
}

.f-control {
  background-color: transparent;
  position: relative;
  height: 34px;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
}

.f-control-chevron {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: $inputText;
}


</style>