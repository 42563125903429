<template>
  <div class="sidebar">
    <div class="p-2 mt-2">
      <div class="mt-2 text-center">
        <b>Тест</b>
      </div>
    </div>
    <b-nav vertical>
      <router-link v-for="(it, k) in nav" :key="k" :to="it.meta.mainPath || it.path" v-slot="{ href, route, navigate }">
        <b-nav-item :href="href" @click="navigate"
                    :active="it.meta.isExactActive"
                    :title="it.name"
                    class="sidebar-nav-item"
        >
          <rl-icon v-if="it.meta.icon" :icon="it.meta.icon" :name="it.name"/>
          <span v-else>{{ it.name }}</span>
        </b-nav-item>
      </router-link>
    </b-nav>
  </div>
</template>

<script>
export default {
  name: "SideBarNav",
  props: {
    nav: {
      type: Array,
      default: () => []
    }
  },
  // watch: {
  //   nav: {
  //     handler(nv) {
  //       console.log(nv);
  //     },
  //     deep: true,
  //   }
  // },
  created() {

    // console.log( JSON.parse(JSON.stringify(this.nav)) );
  }
}
</script>

<style scoped lang="scss">

@import "../assets/styles/variables";

.sidebar {
  width: $sidebarWidth;
  background: $blue-purple;
  height: calc(100% - #{$headerHeight});
  border: none;
  position: fixed;
  left: 0;
  top: $headerHeight;
  z-index: 755;
  color: #fff;

  .header-logo {
    max-width: 40px;
    width: 100%;
    border-radius: 3px;
    display: block;
    margin: 0 auto;
  }
}

.sidebar-nav-item {
  font-size: 22px;

  a {
    color: $inputText;
    padding-top: 25px;
    padding-bottom: 25px;

    &.active,
    &:hover {
      color: $white;
    }
  }
}
</style>