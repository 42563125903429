<template>
  <date-picker
    class="d-block w-100 f-control f-control-datapicker input-lg"
    @change="$emit('change', $event)"
    @input="$emit('input', $event)"
    format="YYYY"
    valueType="format"
    v-model="val"
    type="year"
    :disabled="disabled"
    :placeholder="placeholder"
  />
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';

export default {
  name: "InputYear",
  components: {DatePicker},
  props: {
    value: [String, Number],
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    value(nv){
      this.val = !isNaN(parseInt(nv)) ? String(parseInt(nv)) : null;
    }
  },
  data(){
    return {
      val: this.value,
    };
  },
}
</script>
