<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    :aria-labelledby="name"
    :class="['rl-icon', 'rl-icon-' + icon]"
  >
    <title :id="name" lang="en">{{ title || name }}</title>
    <g fill-rule="evenodd">
      <component :is="icon"></component>
    </g>
  </svg>
</template>

<script>
import User from './icons/User'
import Modelling from './icons/Modelling'
import Stat from './icons/Stat'
import Settings from './icons/Settings'
import Home from './icons/Home'
import BuisnessProcess from './icons/BuisnessProcess'
import System from './icons/System'
import Project from './icons/Project'
import Components from './icons/Components'
import Link from './icons/Link'
import Card from './icons/Card'
import List from './icons/List'
import Trash from './icons/Trash'
import TouchRight from './icons/TouchRight'
import ArrowTop from './icons/ArrowTop'
import Build from './icons/Build'
import Dash from './icons/Dash'
import Effect from './icons/Effect.vue'
import Book from './icons/Book'
import Database from './icons/Database'
import Controls from './icons/Controls'
import Info from './icons/Info'
import Report from './icons/Report'
import SwitchHorizontal from './icons/SwitchHorizontal'

export default {
  components: {
    User, Modelling, Stat , Settings, Home, BuisnessProcess, System, Project, Components, Link,
    Card, List, Trash, TouchRight, ArrowTop, Build, Dash, Database, Book, Controls, Info, SwitchHorizontal, Report, Effect
  },
  props: {
    icon: String,
    name: {
      type: String,
      default: "",
    },
    title: String
  },
};
</script>
