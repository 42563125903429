<template>
  <b-card :class="bordered ? 'card-bordered': 'border-0'" body-class="p-0">
    <b-card-header class="border-0 bg-transparent cursor-pointer" :class="{'active': isOpen}"
                   @click="isOpen = !isOpen">
      <b-icon-chevron-right class="filters-chevron-icon transition" :class="{'rotate-right': isOpen}"/>
      <span class="cc-label">{{ label }}</span>
    </b-card-header>
    <b-collapse id="filters" v-model="isOpen" @show="$emit('show', $event)">
      <b-card class="border-0">
      <slot/>
      </b-card>
    </b-collapse>
  </b-card>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false
    },
    label: {
      type: String
    },
    bordered: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isOpen: this.open
    }
  },
}
</script>

<style lang="scss" scoped>

.cc-label {
  font-size: 14px;
  font-weight: 500;
  margin-left: 1rem;
}

</style>