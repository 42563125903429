<template>
    <span class="tag is-link">
        {{ label }}
        <a class="delete is-small"
           @click="$emit('remove')"/>
    </span>
</template>

<script>
export default {
  name: 'Tag',

  props: {
    label: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>

@import "../../../assets/styles/variables";

.tag {
  margin: 0.1rem 0.2rem 0.1rem 0;
  //styled
  font-size: 14px;
  background: $tagBg;
  border-radius: .2rem;
  line-height: 2;
  color: $tagColor;
  padding: .3rem .5rem;
  display: inline-flex;
  height: auto;
  align-items: center;

  .delete {
    margin-left: 0.2rem;
  }

  &:last {
    margin-right: auto;
  }
}
</style>
