import {layouts, make} from "@/router/support";

const meta = {
    section: 'home',
    layout: layouts.home,
    parent: '/dash'
};

export default [
    make(
        '/dash/main',
        'Тепловая карта',
        () => import(`../views/dash/TotalProgramDash.vue`),
        {...meta}
    ),
]