<template>
  <div class="p-3 bg-light-gray br-8"><slot/></div>
</template>
<script>
export default {

}
</script>

<style scoped>

</style>