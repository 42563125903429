export default {
  namespaced: true,
  state: {
    alerts: [],
  },
  getters: {
    alerts(state) {
      return state.alerts;
    }
  },
  mutations: {
    setAlerts(state, payload) {
      state.alerts = [...state.alerts, payload];
    },
    removeLastAlert(state) {
      state.alerts.pop();
    },
  },
  actions: {
    setAlerts({ commit }, payload) {
      commit('setAlerts', payload);
  
      setTimeout(() => {
        commit('removeLastAlert');
      }, 4000);
    },
  }
}
